@import "vars";
body {
    font-family: var(--font-primary);
    font-size: 16px;
    color: var(--color-primary);
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    font-size: clamp(20px, 4vw, 30px);
}
.sub-heading{
    font-size: clamp(24px, 4.25vw, 54px);
}
.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 3rem;
    max-width: 100%;
    color: #FFF;
    background-color: var(--orange);
    border: none;
    box-sizing: border-box;
    border-radius: 60px;
    font-size: clamp(17px, 3.25vw, 20px);
    transition: all 0.3s ease;
}
.button:hover {
    transform: scale(1.15);
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

img.display-full{
    display: block;
    width: 100%!important;
}

.text-green{
    color: var(--green);
}
.text-white{
    color: var(--white);
}
.text-orange{
    color: var(--orange);
}
.semibold{
    font-weight: 600;
}
.row{
    display: flex;
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
.cke_editable{
    background-color: #cfcfcf;
}

.phone-icon{
    content: url('../assets/phone.svg');
    height: 24px;
    width: 24px;
}
.time-icon{
    content: url('../assets/time.svg');

}
.email-icon{
    content: url('../assets/email.svg');
}

.location-icon{
    content: url('../assets/location.svg');
}

.horizontal-list{
    display: flex;
    list-style: none;
    margin:0;
    padding:0;
    & > li{
        flex: 1 1 0;
    }
    gap:2rem;
    @media screen and (max-width:768px) {
       flex-wrap: wrap;
       & > li{
            flex: auto;
            justify-content: center;
            text-align: center;
        }
    }
}
.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    margin:0;
    padding:0;
    grid-gap:2rem;
    @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
    }
}
.four-col-pdf-download{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin:0;
    padding:0;
    grid-gap:2rem 1rem;
    @media screen and (max-width:998px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width:576px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:300px) {
        grid-template-columns: 1fr;
    }
    li{
        position: relative;
        display:flex;
        flex-direction: column;
    
        max-width: 100%;
        min-height: 80px;
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            text-decoration: none;
            height: 100%;
            img{
                width: 100%!important;
                height: 100%!important;
                object-fit: cover;
            }
        
            &::before,
            &::after {
                position: absolute;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
            &::before {
                content:"";
                width: 100%;
                height: 100%;
                background-color: rgba(67,67,67,.91);
                mix-blend-mode: multiply;
                z-index: 1;
            }
            &:after {
                z-index: 5;
                content: "View";
                display: inline-block;
                padding: .5rem 2rem;
                color: #FFF;
                border-radius: 60px;
                background-color: var(--orange);
            }
            &:hover {
                color: var(--orange)!important;
                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    
       
    }
}



